.section--hero {
  background: url(/public/images/bg-mobile.jpg) no-repeat;
  background-size: cover;
  background-position: center bottom -60px;
  height: 100vh;
  position: relative;

  .full-height {
    height: 100vh;
    align-content: end;
  }

  .title-big {
    font-size: 24px;
  }

  .gradient {
    position: relative;
    padding-bottom: 80px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 40%, white 100%);
      z-index: 1; /* Ensures the gradient is on top of the image */
    }

    & > * {
      position: relative;
      z-index: 2; /* Ensures text and other content are above the gradient */
    }
  }

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    background: url(/public/images/bg.jpg) no-repeat;
    background-position: center top;

    .full-height {
      align-content: center;
    }

    .gradient {
      padding-bottom: 0;
    }

    .title-big {
      font-size: 47px;
    }
  }

  .scroll-arrow {
    position: absolute;
    bottom: 40px;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 2px solid $dark-grey;
    border-bottom: 2px solid $dark-grey;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb05 1.5s infinite;
    animation: sdb05 1.5s infinite;
    box-sizing: border-box;
    z-index: 2;
  }

  @-webkit-keyframes sdb05 {
    0% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      -webkit-transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
  }

  @keyframes sdb05 {
    0% {
      transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
  }
}
