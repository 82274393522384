p {
  font-size: 20px;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}

.text-big {
  font-size: 20px;
  line-height: 1.2;

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    font-size: 32px;
  }
}

.text-medium {
  font-size: 16px;
}

.title-big {
  font-size: 32px;

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    font-size: 47px;
  }
}

.title-small {
  font-size: 20px;
}

.link-black {
  color: black;
}

.text-small {
  font-size: 14px;
}
