.terms {
  margin-top: 100px;
  padding-bottom: 50px;

  h1 {
    font-size: 36px;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 24px;
    margin-top: 30px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }
}
