.section {
  &--padding {
    padding: 50px 0;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      padding: 50px 0;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding: 100px 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  &--xs-padding {
    padding: 50px 0;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      padding: 0;
    }
  }

  &--dark {
    background: $dark;
    color: white;

    a {
      color: white;
    }
  }

  &--grey {
    background: $grey;
  }

  img {
    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      max-width: 100%;
    }
  }
}
