.quote {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: url(/public/images/quote.svg) no-repeat right bottom;

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    flex-direction: row;
  }

  img {
    width: 100px;
    border-radius: 50%;
  }

  p {
    margin: 0;
    color: $dark-grey;

    strong {
      color: black;
    }
  }
}
