.section {
  &_feature {
    margin-bottom: 50px;

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      margin-bottom: 200px;
    }

    &:first-of-type {
      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        margin-top: 50px;
        margin-bottom: 100px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;

      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        margin-bottom: 100px;
      }
    }

    h2 {
      margin-bottom: 20px;
    }

    svg {
      margin-bottom: 30px;
    }

    .img-agenda {
      margin-left: -170px;
      height: 740px;
      position: relative;
      margin-bottom: -200px;

      @media (min-width: map-get($grid-breakpoints, 'md')) {
        top: -55px;
        height: 500px;
      }

      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        top: -150px;
        height: 740px;
      }

      img {
        position: absolute;
        height: auto;

        &.img-devices {
          width: 670px;
          margin-left: 110px;

          @media (min-width: map-get($grid-breakpoints, 'md')) {
            margin-top: -76px;
            width: 500px;
            margin-left: 160px;
          }

          @media (min-width: map-get($grid-breakpoints, 'lg')) {
            width: 750px;
            margin-top: 0;
            margin-left: 110px;
          }

          @media (min-width: map-get($grid-breakpoints, 'xl')) {
            width: 855px;
          }
        }

        &.img-mobile {
          width: 350px;
          top: 80px;
          margin-top: 215px;

          @media (min-width: map-get($grid-breakpoints, 'md')) {
            margin-top: 55px;
            margin-left: 101px;
            width: 200px;
          }

          @media (min-width: map-get($grid-breakpoints, 'lg')) {
            width: 323px;
            margin-top: 208px;
            margin-left: 0;
          }

          @media (min-width: map-get($grid-breakpoints, 'xl')) {
            width: 410px;
            margin-top: 300px;
          }
        }
      }
    }

    .img-anamnesis {
      margin-left: -70px;
      width: 133%;
      position: absolute;

      @media (min-width: map-get($grid-breakpoints, 'md')) {
        top: 90px;
      }

      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        width: 146%;
        margin-left: -105px;
        top: 50px;
      }

      @media (min-width: map-get($grid-breakpoints, 'xl')) {
        top: -25px;
      }
    }

    .img-treatments {
      width: 115%;
      position: absolute;

      @media (min-width: map-get($grid-breakpoints, 'md')) {
        margin-left: -57px;
        top: 125px;
      }

      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        margin-left: -145px;
        top: 0;
      }

      @media (min-width: map-get($grid-breakpoints, 'xl')) {
        margin-left: -145px;
        top: -15px;
      }
    }

    .img-finances {
      position: relative;

      @media (min-width: map-get($grid-breakpoints, 'md')) {
        margin-top: 70px;
      }

      @media (min-width: map-get($grid-breakpoints, 'lg')) {
        top: -35px;
        margin-bottom: -70px;
      }

      @media (min-width: map-get($grid-breakpoints, 'xl')) {
        top: -100px;
      }

      img {
        position: absolute;
        height: auto;
        border: 1px solid $grey;
        border-radius: 2px;

        &.img-stats {
          transform: perspective(500px) rotateY(-17deg);
          box-shadow: 23px 38px 54px -22px rgba(0, 0, 0, 0.75);
          width: 100%;
        }
      }
    }
  }
}

#features {
  overflow: hidden;
}
