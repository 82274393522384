// Tooltip styles for add-on modules
.tooltip-container {
  position: relative;
  display: inline-flex;
  margin-left: 3px;
  cursor: pointer;
  flex-shrink: 0;

  .info-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
  }

  .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    font-weight: normal;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    pointer-events: none;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #333 transparent transparent transparent;
    }
  }

  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
}

.addon-module.selected .tooltip-container .info-icon {
  filter: brightness(0) invert(1);
}

.section {
  &--plans {
    .card {
      height: 100%;
      position: relative;

      .promo {
        text-transform: uppercase;
        background: $primary;
        color: white;
        position: absolute;
        font-size: 12px;
        top: -1px;
        right: -1px;
        border-radius: 0 0.25rem 0 0.25rem;
        padding: 3px 8px 5px;
        line-height: 12px;
      }

      p,
      .price {
        border-bottom: 1px solid $gray-500;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: left;

        li {
          padding: 0;
          background: url(/public/images/check.svg) no-repeat left;
          padding-left: 30px;
          margin-bottom: 15px;
          position: relative;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 5px;

          .tooltip-container {
            .info-icon {
              width: 14px;
              height: 14px;
            }
          }

          &.disabled {
            background: url(/public/images/denied.svg) no-repeat left;
          }

          &.faded {
            opacity: 0.4;
          }

          .soon {
            background: $primary;
            color: white;
            padding: 3px 9px;
            border-radius: 20px;
            font-size: 12px;
          }

          b {
            color: $primary;
          }

          &:last-of-type {
            margin: 0;
          }
        }
      }

      &#plan-1 {
        ul {
          border-bottom: 1px solid $gray-500;
          padding-bottom: 20px;
          margin-bottom: 20px;

          @media (min-width: map-get($grid-breakpoints, 'md')) {
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
          }
        }
      }

      .price {
        font-size: 45px;
        font-weight: bold;
        line-height: 45px;

        .discounted {
          color: $danger;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            width: 105px;
            border-top: 2px solid #dc3545;
            left: -12px;
            top: 32px;
            transform: rotate(350deg);
          }
        }

        &.price-small {
          font-size: 30px;
        }

        b {
          font-size: 20px;
        }

        sup {
          font-size: 20px;
        }
      }

      .card-footer {
        background: transparent;
        border: 0;
      }
    }
  }
}

// Add-on Module Styles
.addon-module {
  cursor: pointer;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 5px;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addon-module.selected {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #fff;
}

.addon-module .module-name {
  font-weight: 500;
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 80%;
}

.addon-module .module-price {
  background: $primary;
  color: #fff;
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 12px;
  white-space: nowrap;
  margin-left: 8px;
  flex-shrink: 0;
}

// Toggle Switch Styles for Monthly/Yearly Pricing
.price-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin: 0 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #1890ff;
}

input:checked + .slider::before {
  transform: translateX(26px);
}

.toggle-label {
  font-size: 14px;
  color: white;
}
