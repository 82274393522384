.navbar {
  transition: background-color 0.3s ease;

  a:not(.btn) {
    color: black;
  }

  &.scrolled {
    background: white;
  }

  .navbar-nav {
    flex-direction: row;

    .nav-item {
      margin-left: 20px;

      .nav-link {
        @media (min-width: map-get($grid-breakpoints, 'md')) {
          font-size: 13px;
        }

        @media (min-width: map-get($grid-breakpoints, 'lg')) {
          font-size: 16px;
        }

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .navbar-brand {
    img {
      height: 32px;
    }
  }
}
