@font-face {
  font-family: 'Moderat-Bold';
  src:
    url("/public/fonts/Moderat-Bold.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/Moderat-Bold.otf") format("opentype"),
    url("/public/fonts/Moderat-Bold.woff") format("woff"),
    url("/public/fonts/Moderat-Bold.ttf") format("truetype"),
    url("/public/fonts/Moderat-Bold.svg#Moderat-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Moderat';
  src:
    url("/public/fonts/Moderat-Regular.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/Moderat-Regular.otf") format("opentype"),
    url("/public/fonts/Moderat-Regular.woff") format("woff"),
    url("/public/fonts/Moderat-Regular.ttf") format("truetype"),
    url("/public/fonts/Moderat-Regular.svg#Moderat-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
