@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "fonts";
@import "typography";
@import "partials/navbar";
@import "partials/section";
@import "partials/hero";
@import "partials/features";
@import "partials/plans";
@import "partials/testimonials";
@import "partials/_contacts";
@import "partials/footer";
@import "partials/video";
@import "partials/terms";

body {
  font-family: Moderat, sans-serif;
  overflow-x: hidden;
}

.align-content-center {
  -webkit-align-items: center;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
