.section {
  &--contacts {
    .contacts {
      font-size: 24px;
      margin-top: 20px;

      .row {
        .col-md-6 {
          margin-bottom: 20px;
        }

        @media (min-width: map-get($grid-breakpoints, 'md')) {
          margin-bottom: 20px;

          .col-6 {
            margin-bottom: 0;
          }
        }
      }

      a {
        color: $dark-grey;
        text-decoration: none;

        svg {
          font-size: 35px;
          vertical-align: middle;
          margin-right: 10px;

          @media (min-width: map-get($grid-breakpoints, 'lg')) {
            font-size: 40px;
          }
        }
      }
    }
  }
}
